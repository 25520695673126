<template>
  <div v-loading="loading" :element-loading-text="'加载中'" class="exam">
    <div class="exam-con">
      <div class="top-content">
        <div class="top-left">
          <img class="item-image" src="@/assets/img/Home/question/bank-icon.png">
          <div class="kcName ellipsis">{{ kcName }}</div>
        </div>
      </div>
      <div class="block">
        <div>
          <div class="question-wrap">
            <div v-for="(item, index) of subList" ref="queCarBox" :key="index">
              <que-car ref="queCar" paper-id="paperId" :is-purchase="true" :start="true" :detail="item"
                :detail-id="index" :is-submited="isSubmit" :is-ti-ku="true" :is-points="false" @task="getNum" />
            </div>
          </div>
          <!-- 右侧 -->
          <div class="shiti-function">
            <div class="timebox">
              <i class="iconfont icon-daojishi" />
              {{ isSubmit ? '共计用时' : '答题时间' }}
              <span v-if="time" class="countdown">
                {{ time.hours > 9 ? time.hours : '0' + time.hours }}:{{
                  time.minutes > 9 ? time.minutes : '0' + time.minutes
                }}:{{ time.seconds > 9 ? time.seconds : '0' + time.seconds }}
              </span>
            </div>
            <!-- 答题卡 -->
            <div class="sheet numberPannel m-t-20">
              <div class="sheet-header">
                <!-- 练习记录 -->
                <template v-if="isRecord">
                  <div class="title">
                    答题卡
                    <img class="icon" src="@/assets/img/Home/hu.png">
                  </div>
                  <div class="flex">
                    <template>
                      <span class="num">
                        <i class="iconfont icon-dian" style="color: #38c25f" />
                        正确
                        {{ answerData.ZQsubjectName }}
                      </span>
                      <span class="num">
                        <i class="iconfont icon-dian" style="color: #ff5d51" />
                        错误
                        {{ answerData.SubjectName - answerData.ZQsubjectName - answerData.NosubjectName }}
                      </span>
                      <span class="num">
                        <i class="iconfont icon-dian" style="color: #666" />未答
                        {{ answerData.NosubjectName }}
                      </span>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div v-if="!isSubmit" class="title">
                    答题卡
                    <img class="icon" src="@/assets/img/Home/hu.png">
                  </div>
                  <div class="flex">
                    <template v-if="!isSubmit">
                      <span class="num">
                        <i class="iconfont icon-dian" style="color: #38c25f" />
                        已答{{ complete }}
                      </span>
                      <span class="num">
                        <i class="iconfont icon-dian" style="color: #666" />
                        未答{{ subList.length - complete }}
                      </span>
                    </template>
                    <template v-else>
                      <span class="num">
                        <i class="iconfont icon-dian" style="color: #38c25f" />
                        正确
                        {{ answerData.ZQsubjectName }}
                      </span>
                      <span class="num">
                        <i class="iconfont icon-dian" style="color: #ff5d51" />
                        错误
                        {{ answerData.SubjectName - answerData.ZQsubjectName - answerData.NosubjectName }}
                      </span>
                      <span class="num">
                        <i class="iconfont icon-dian" style="color: #666" />未答
                        {{ answerData.NosubjectName }}
                      </span>
                      <span class="num" style="margin-left: 4px">正确率: {{ answerData.accuracy }}</span>
                    </template>
                  </div>
                </template>
              </div>
              <!-- 试题类型 -->
              <div class="sheet-block">
                <div v-for="(shi, shiIndex) of subList" :key="shiIndex" class="t-item">
                  <div v-if="isSubmit" class="t-item" :class="answerType(shi)" @click="jumpShiTi(shiIndex + 1)">
                    {{ shiIndex + 1 }}
                  </div>
                  <!-- 未提交 -->
                  <div v-else class="t-item" :class="[shi.questionType == 1 || shi.questionType == 6
                    ? shi.questionUserAnswer && shi.questionUserAnswer.length > 0
                      ? 't-on'
                      : ''
                    : shi.questionUserAnswer
                      ? 't-on'
                      : '']
                    " @click="jumpShiTi(shiIndex + 1)">
                    {{ shiIndex + 1 }}
                  </div>
                </div>
              </div>
              <!-- 交卷 -->
              <div v-if="!isRecord" class="btn-bottom">
                <div class="line" />
                <div v-if="!isSubmit" class="btnlist" @click="ShowAnswerChange">我已答完,立即提交</div>
                <div v-if="isSubmit && examType != 3" class="btnlist" @click="answerAgin">继续练习</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import queCar from './queCar.vue'
import {
  getAnswerQuestListByMode,
  getFallibleAnswerQuestListByMode,
  getWrongAnswerQuestListByMode,
  addPcSubjectAnswer,
  addFalliblePcSubjectAnswer,
  addWrongPcSubjectAnswer,
  getPcSubjectAnswer
} from '@/api/question'
import { getInfo } from '@/api/cookies'
export default {
  components: { queCar },
  data() {
    return {
      kId: '',
      kcName: '',
      loading: false,
      IsPurchase: false,
      subjectList: [], // 题目列表//提交时用勇于显示题号
      subList: [], // 题目列表-提交时用
      currentQuestion: 0, // 当前的题
      examType: '', // 试做try
      type: '', // 0:客观题题库/1:易错错题库/2:个人错题库
      userInfo: {},
      start: true, // 是否计时
      timeEnd: '',
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      /* 做题数 */
      complete: 0,
      showAnswerCard: false,
      answerData: {},
      isSubmit: false,
      isRecord: false, // 是否是练习记录
      bool: false // 组合题是否答题
    }
  },
  async created() {
    this.kId = this.$route.query.kId
    this.kcName = this.$route.query.kcName
    this.examType = this.$route.query.examType
    this.isRecord = this.$route.query.isRecord
    this.type = this.$route.query.type
    this.userInfo = getInfo()
    await this.getAnswerQuestList()
    // 滚动条监控  答题卡 滚动到顶部悬浮
    // if (this.IsPurchase) {
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      $(window).scroll(function () {
        const top = $(window).scrollTop()
        if (top >= 24) {
          $('.shiti-function').addClass('fixed-card')
        } else {
          $('.shiti-function').removeClass('fixed-card')
        }
      })
    })

    // 如果isSubmit存在 代表观看练习记录
    if (this.$route.query.isSubmit) {
      this.isSubmit = true
      this.loading = true
      const answerTimes = this.$route.query.answerTimes
      const result = parseInt(answerTimes)
      this.time.hours = Math.floor(result / 3600)
      this.time.minutes = Math.floor((result / 60 % 60))
      this.time.seconds = Math.floor((result % 60))
      await this.getAnswer(this.$route.query.recordId)
      this.loading = false
      return
    }
    this.timeInterval()
  },
  methods: {
    // 试题列表
    async getAnswerQuestList() {
      this.loading = true
      let res = {}
      if (this.type == 0) {
        // 客观题-获取试题列表
        res = await getAnswerQuestListByMode({
          kId: this.kId,
          userId: this.userInfo && this.userInfo.id,
          mode: 2
        })
      } else if (this.type == 1) {
        // 易错题-获取试题列表
        res = await getFallibleAnswerQuestListByMode({
          kId: this.kId,
          userId: this.userInfo && this.userInfo.id,
          mode: 2
        })
      } else {
        // 错题库-获取试题列表
        res = await getWrongAnswerQuestListByMode({
          kId: this.kId,
          userId: this.userInfo && this.userInfo.id,
          mode: 2
        })
        if (res.data.length == 0) {
          clearTimeout(this.timeEnd) // 清除
          this.$alert('您的个人错题库已清空！', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            confirmButtonClass: 'btn-red',
            callback: action => {
              this.$router.go(-1)
            }
          })
          return
        }
      }
      await this.splicing(res.data, false)
      // for (let i in this.subList) {
      //   let item = this.subList[i]
      //   item.indexVal = Number(i) + 1
      // }
      this.loading = false
    },
    splicing(list, ans) {
      this.TiXing = [0, 0, 0, 0, 0, 0]
      /* ans  是否是提交显示的 */
      let shiti = []
      for (const item of list) {
        item.questionType = Number(item.questionType)
        if (item.questionType == 0 || item.questionType == 1 || item.questionType == 6) {
          const data = JSON.parse(item.questionOption)
          const sub = []
          for (const i in data) {
            const val = {
              val: data[i],
              num: String.fromCharCode(Number(i) + 65)
            }
            sub.push(val)
          }
          item.optionList = sub
          if (ans && (item.questionType == 1 || item.questionType == 6)) {
            item.questionUserAnswer = []
          }
          if (!ans && (item.questionType == 1 || item.questionType == 6)) {
            if (item.questionUserAnswer) {
              item.questionUserAnswer = item.questionUserAnswer.split(',')
            } else {
              item.questionUserAnswer = []
            }
          }
          shiti.push(item)
        } else if (item.questionType == 2) {
          item.optionList = [
            { val: '对', num: 'A' },
            { val: '错', num: 'B' }
          ]
          shiti.push(item)
        } else if (item.questionType == 5) {
          if (item.childSubject) {
            for (const chil of item.childSubject) {
              chil.questionType = Number(chil.questionType)
              if (chil.questionType == 0 || chil.questionType == 1 || chil.questionType == 6) {
                const data = JSON.parse(chil.questionOption)
                const sub = []
                for (const i in data) {
                  const val = {
                    val: data[i],
                    num: String.fromCharCode(Number(i) + 65)
                  }
                  sub.push(val)
                }
                chil.optionList = sub
                if (ans && (chil.questionType == 1 || chil.questionType == 6)) {
                  chil.questionUserAnswer = []
                }
                if (!ans && (chil.questionType == 1 || chil.questionType == 6)) {
                  if (chil.questionUserAnswer) {
                    chil.questionUserAnswer = chil.questionUserAnswer.split(',')
                  } else {
                    chil.questionUserAnswer = []
                  }
                }
              } else if (chil.questionType == 2) {
                chil.optionList = [
                  { val: '对', num: 'A' },
                  { val: '错', num: 'B' }
                ]
              }
            }
            shiti = shiti.concat(item.childSubject)
          }
        } else {
          shiti.push(item)
        }
      }
      this.subjectList = shiti
      this.subList = list // 提交时用  同数据源数据会一起变化
      // 添加 字段
      // for (let i in this.subjectList) {
      //   let item = this.subjectList[i]
      //   item.indexVal = Number(i) + 1
      //   this.TiXing[item.questionType]++
      // }
      this.subList.forEach((element, index) => {
        this.$set(element, 'indexVal', index + 1)
      })
    },
    // 提交
    async ShowAnswerChange() {
      const that = this
      if (this.userInfo != null) {
        if (this.subList.length - this.complete > 0) {
          this.$confirm('检测到您有未答的题目，是否确认提交？', '提示', {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              that.postEvery()
            })
        } else {
          this.$confirm('确认交卷吗？', '提示', {
            confirmButtonText: '提交',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              that.postEvery()
            })
        }
      }
      this.canClick = true
      this.ifNew = false
      this.$nextTick(() => {
        this.ifNew = true
      })
      this.$forceUpdate()
    },
    // 重新答题
    answerAgin() {
      if (this.$route.query.isSubmit) {
        this.$router.push(`/quesTion/examTest?kId=${this.kId}&kcName=${this.kcName}&type=${this.type}`)
        return
      }
      window.location.reload()
    },
    // 计算做题数
    async getNum() {
      this.complete = 0
      for (const item of this.subList) {
        if (item.questionType == 1 || item.questionType == 6) {
          if (item.questionUserAnswer.length > 0) {
            await this.complete++
          }
        } else if (item.questionType == 5 && item.childSubject) {
          // 组合题判断是否选择一个题
          this.bool = item.childSubject.some(function (element, index) { return element.questionUserAnswer.length })
          if (this.bool) {
            item.questionUserAnswer = 'aaa'
          }

          await this.complete++
        } else {
          if (item.questionUserAnswer) {
            await this.complete++
          }
        }
      }
      const data = (this.complete / Number(this.subList.length)) * 100
      this.baifen = Math.round(data)
      this.$forceUpdate()
    },
    // 查询提交报告
    async getAnswer(recordId) {
      const { data } = await getPcSubjectAnswer({ recordId })
      this.answerData = data
      await this.splicing(data.arrayList, false)
    },
    // 提交答案
    async postEvery() {
      this.loading = true
      const times =
        (await (this.time.hours > 0 ? this.time.hours * 60 * 60 : 0)) +
        (this.time.minutes > 0 ? this.time.minutes * 60 : 0) +
        this.time.seconds
      const data = await {
        questionSubjectPaperVoList: this.subList,
        answerTimes: times || 0,
        courseId: this.kId,
        mode: 2
      }
      // 提交试卷
      let res = {}
      if (this.type == 0) {
        res = await addPcSubjectAnswer(data)
      } else if (this.type == 1) {
        res = await addFalliblePcSubjectAnswer(data)
      } else {
        res = await addWrongPcSubjectAnswer(data)
      }
      if (res.code == 0) {
        clearTimeout(this.timeEnd) // 清除
        this.loading = false
        await this.getAnswer(res.data)
      } else {
        this.loading = false;
        return
      }
      this.$nextTick(() => {
        this.isSubmit = true
        // 监听滚动事件
        window.addEventListener('scroll', this.scrollToTop)
        this.$forceUpdate()
      })
    },
    // 答题卡颜色
    answerType(item) {
      if (item.isCorrect == 0) {
        return 't-error'
      } else if (item.isCorrect == 1) {
        return 't-sucess'
      } else {
        return 't-no'
      }
    },
    // 计时器
    timeInterval() {
      this.timeEnd = setInterval(() => {
        // 回掉函数开始计时了
        this.time.seconds++
        if (this.time.seconds == 60) {
          this.time.seconds = 0
          this.time.minutes++
        }
        if (this.time.minutes == 60) {
          this.time.minutes = 0
          this.time.hours++
        }
      }, 1000)
    },
    // 跳转试题
    jumpShiTi(id) {
      const top = document.getElementById(id).offsetTop
      window.scrollTo(0, top)
    },
    scrollToTop() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.isElementNotInViewport(this.$refs.queCarBox)
      }, 500)
    },
    isElementNotInViewport(el) {
      if (el) {
        el.forEach((item, index) => {
          const rect = el[index].getBoundingClientRect()
          if (
            !(rect.top >= (window.innerHeight || document.documentElement.clientHeight) || rect.bottom <= 0)
          ) {
            if (!this.subList[index].isShow) {
              this.$refs.queCar[index].init()
              this.subList[index].isShow = true
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="less">
.btn-red:active {
  background: #ff5d51 !important;
  border-color: #ff5d51 !important;
}

.btn-red {
  background: #ff5d51 !important;
  border-color: #ff5d51 !important;
}
</style>
<style lang="less" scoped>
.exam-con {
  width: 1220px;
  margin: 0px auto;

  .top-content {
    width: 100%;
    height: 60px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 49px 0 20px;

    .top-left {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;

      .item-image {
        width: 51px;
        height: 51px;
        margin-right: 12px;
      }

      .kcName {
        max-width: 1100px;
        overflow: hidden;
      }
    }

    .report-btn {
      width: 109px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #ff5e51;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff5e51;
      text-align: center;
      line-height: 23px;
      cursor: pointer;
    }
  }

  .shijuan-block {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 20px 30px;
    margin-bottom: 20px;
    background-color: #fff;

    margin-top: 30px;

    .shijuan-type {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;

      i {
        font-size: 20px;
        margin-right: 5px;
      }
    }

    .shijuan-title {
      text-align: center;
      font-size: 22px;
      margin: 0;
      padding: 2px 0px 27px;
      border-bottom: 1px dashed #e0e3e9;

      .grade {
        position: absolute;
        left: 40px;
        top: 0px;
        width: 150px;
        height: 80px;
        font-size: 50px;
        font-family: qiantumakeshouxieti;
        font-weight: 400;
        line-height: 80px;
        color: #ff5104;
      }
    }

    .content {
      font-size: 16px;
      color: #666;
      line-height: 2;
      padding-left: 60px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .warning {
    background-color: #fff9e9;
    border: 1px dashed #ffeec4;
    text-align: center;
    line-height: 2.3;
    font-size: 16px;
    color: #ed7321;
  }

  .block {
    width: 1220px;
    margin: auto;
    overflow: hidden;
    margin-bottom: 68px;

    .question-wrap {
      float: left;
      width: 880px;
      position: relative;
      background: #ffffff;
      border-radius: 8px;

      .bot-btn {
        width: 100%;
        height: 50px;
        border-top: #eeeeee 1px solid;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        cursor: pointer;

        .disable {
          color: #999999;
          cursor: not-allowed;
        }

        >span {
          width: 320px;
          height: 50px;
          line-height: 50px;
          text-align: center;

          >.iconfont {
            font-size: 10px;
            margin: 0 4px;
          }
        }
      }
    }

    // <!-- 左侧 -->
    .shiti-function {
      width: 320px;
      float: right;
      min-height: 300px;

      /* 答题时间 */
      .timebox {
        width: 100%;
        height: 80px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 80px;
        padding-left: 31px;

        .iconfont {
          font-size: 18px;
          margin-right: 9px;
        }

        .countdown {
          color: #ff5d51;
        }

        .red {
          color: red !important;
        }
      }

      .sheet {
        width: 100%;
        background-color: #fff;
        padding: 0 13px 113px;
        position: relative;

        .sheet-header {
          width: 100%;
          line-height: 58px;
          height: 58px;
          border-bottom: 1px solid #eee;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          .title {
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            flex-shrink: 0;
            position: relative;

            .icon {
              position: absolute;
              left: 50%;
              transform: translate(-50%);
              top: 19px;
              width: 18px;
              height: 6px;
            }
          }

          .num {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 14px;

            .iconfont {
              font-size: 8px;
              margin-left: 2px;
              margin-right: 2px;
            }
          }
        }

        .sheet-block {
          padding: 15px 10px 15px;
          max-height: 360px;
          overflow-y: auto;
          display: flex;
          flex-wrap: wrap;

          .t-item {
            width: 40px;
            height: 30px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
            margin: 6px;

            .t-item {
              border: 1px solid #eeeeee;
              line-height: 28px;
              margin: 0px;
            }

            &:hover {
              background-color: #ee491f;
              color: #f5deb3;
            }
          }

          .t-on {
            border: 1px solid #38c25f;
            background: #38c25f;
            color: #ffffff;
          }

          .t-error {
            border: 1px solid #ff5d51;
            background: #ff5d51;
            color: #ffffff;
          }

          .t-no {
            border: 1px solid #666;
            background: #666;
            color: #ffffff;
          }

          .t-sucess {
            border: 1px solid #38c25f;
            background: #38c25f;
            color: #ffffff;
          }
        }

        .btn-bottom {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);

          .line {
            background: #eeeeee;
            height: 1px;
            width: 276px;
            margin-top: 56px;
          }

          .btnlist {
            width: 200px;
            height: 40px;
            line-height: 40px;
            margin: 16px auto 0;
          }
        }
      }
    }

    /*  */
    .fixed-card {
      position: fixed;
      top: 116px;
      right: calc(50% - 610px);
    }
  }

  .btnlist {
    background: #ff5d51;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
}
</style>
